import React from "react"
import {
  Icon,
  Segment,
  Container,
  Header,
  Divider,
  List,
} from "semantic-ui-react"
import "./layout.css"

const Footer = ({ year }) => {
  return (
    <Segment
      vertical
      inverted
      size="mini"
      textAlign="center"
      style={{
        padding: "5em 0em",
        boxShadow: "-8px 15px 30px rgba(133,141,148,.66)",
        backgroundColor: "#000",
      }}
    >
      <Container textAlign="center">
        <Header as="h3" content={`Quantic ©${year}`} inverted />
        <Divider inverted section style={{ margin: "0.15em 0" }} />
        <List
          horizontal
          inverted
          divided
          link
          size="small"
          style={{ marginTop: "0.25em" }}
        >
          <List.Item as="a" href="#" target="_blank">
            <Icon name="facebook official" size="big" />
          </List.Item>
          <List.Item
            as="a"
            href="https://www.instagram.com/artesanalcoffeeshop/"
            target="_blank"
          >
            <Icon name="instagram" size="big" />
          </List.Item>
        </List>
      </Container>
    </Segment>
  )
}

export default Footer
