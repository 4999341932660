import PropTypes from "prop-types"
import React, { useState } from "react"
import { createMedia } from "@artsy/fresnel"
import {
  Segment,
  Menu,
  Header,
  Dropdown,
  Flag,
  Sidebar,
  Visibility,
  Icon,
  Container,
} from "semantic-ui-react"
import {
  injectIntl,
  IntlContextConsumer,
  changeLocale,
  Link,
} from "gatsby-plugin-intl"

import logo from "../images/OriginalSmaller.png"

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})
const MenuItems = ({ children, to }) => {
  return (
    <Link
      to={to}
      className="link-hover"
      style={{ color: "white", marginRight: 15, marginTop: 15 }}
    >
      <Menu.Item
        className="link-hover"
        style={{ justifyContent: "center", margin: "0.5em 0" }}
      >
        <Header
          className="link-hover"
          as="h3"
          style={{ fontFamily: "Glacial" }}
          content={children}
          inverted
        />
      </Menu.Item>
    </Link>
  )
}

const languageName = {
  es: "Español",
  en: "English",
}
const Language = ({ intl }) => {
  const languageClick = (e, language) => {
    e.preventDefault()
    changeLocale(language)
  }
  return (
    <Dropdown
      item
      style={{ fontFamily: "Glacial", margin: "14px 0px" }}
      text={
        <Header
          className="link-hover"
          as="h3"
          style={{ fontFamily: "Glacial" }}
          content={intl.formatMessage({ id: "navbar.language_drop" })}
          inverted
        />
      }
    >
      <Dropdown.Menu>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <Dropdown.Item
                key={language}
                onClick={e => languageClick(e, language)}
                style={{
                  color: currentLocale === language ? `yellow` : `black`,
                }}
                href="/"
              >
                <Flag name={language === "en" ? "us" : language} />
                {languageName[language]}
              </Dropdown.Item>
            ))
          }
        </IntlContextConsumer>
      </Dropdown.Menu>
    </Dropdown>
  )
}
const DesktopContainer = ({ intl, children }) => {
  const [fixed, setFixed] = useState(false)
  const hideFixedMenu = () => setFixed(false)
  const showFixedMenu = () => setFixed(true)
  return (
    <header>
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={showFixedMenu}
          onBottomPassedReverse={hideFixedMenu}
        >
          <Segment
            inverted
            // vertical
            style={{ backgroundColor: "#2C2C2C" }}
            textAlign="center"
          >
            <Menu
              inverted={!fixed}
              pointing={!fixed}
              size="large"
              secondary
              inverted
              attached="top"
              stackable
              aling="left"
            >
              <Link to="/">
                <img
                  alt="quantic-logo"
                  src={logo}
                  width={140}
                  height={80}
                  style={{ margin: "0.5em 0.4em" }}
                />
              </Link>
              <Menu.Menu position="right">
                <MenuItems to="/">
                  {" "}
                  {intl.formatMessage({ id: "navbar.home" })}
                </MenuItems>
                <MenuItems to="/about/">
                  {intl.formatMessage({ id: "navbar.about" })}
                </MenuItems>
                <MenuItems to="/artesanal">
                  {intl.formatMessage({ id: "navbar.artesanal" })}
                </MenuItems>
                <Language intl={intl}/>
              </Menu.Menu>
            </Menu>
          </Segment>
        </Visibility>
        {children}
      </Media>
    </header>
  )
}

DesktopContainer.propTypes = {
  siteTitle: PropTypes.string,
}

DesktopContainer.defaultProps = {
  siteTitle: ``,
}
// MOBILE
const MobileContainer = ({ intl, children }) => {
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const handleSidebarHide = () => setSidebarOpened(false)
  const handleToggle = () => setSidebarOpened(true)
  return (
    <header>
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Menu position="right">
              <MenuItems to="/">
                {" "}
                {intl.formatMessage({ id: "navbar.home" })}
              </MenuItems>
              <MenuItems to="/about/">
                {intl.formatMessage({ id: "navbar.about" })}
              </MenuItems>
              <MenuItems to="/artesanal">
                {intl.formatMessage({ id: "navbar.artesanal" })}
              </MenuItems>
              <Language intl={intl} />
            </Menu.Menu>
          </Sidebar>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign="center"
              style={{ minHeight: 150, padding: "1em 0em" }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Link to="/">
                    <img
                      alt="quantic-logo"
                      src={logo}
                      width={140}
                      height={80}
                      style={{ margin: "0.5em 0.4em" }}
                    />
                  </Link>
                  <Menu.Menu position="right">
                    <Menu.Item onClick={handleToggle}>
                      <Icon name="sidebar" size="big" />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              </Container>
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    </header>
  )
}

const ResponsiveContainer = ({ children, intl }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer intl={intl}>{children}</DesktopContainer>
    <MobileContainer intl={intl}>{children}</MobileContainer>
  </MediaContextProvider>
)
export default injectIntl(ResponsiveContainer)
